#slides {
    .slideFrame { 
        height: 50%; 
        border: 1px solid #000; 
        overflow: hidden; 
    }

    .slide_bar { 
        width: 100%; 
        height: 100%; 
        font-size: 0; 
        white-space: nowrap; 
        transition-duration: 1s; 
    }

    img { 
        width: 100%; 
        height: 100%; 
    }
}