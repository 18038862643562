#language-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .flag {
        display: inline-block;
        width: 25px;
        height: 17px;
        background-size: cover;
        border: 1px solid #000;
        box-sizing: content-box;
        margin-top: 5px;
    }

    @media (max-width: 991px) {
        > .nav-item {
            margin: 10px;
        }
    }
}

#flag-pl {
    background: url(../../../images/flags.jpg) 0 0 no-repeat;
}

#flag-en {
    background: url(../../../images/flags.jpg) -25px 0 no-repeat;
}

#flag-ru {
    background: url(../../../images/flags.jpg) -50px 0 no-repeat;
}
