#left-content,
#right-content {
    .nav-pills {
        border-bottom: 0px solid rgba(0, 0, 0, 0.3) !important;

        > .nav-link {
            height: 45px;
            border: 0px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.4);
            color: #628F82 !important;
            text-transform: uppercase;
            border-radius: 0;
            font-size: 16px;
            padding: 1rem;

            &:hover, &:focus {
                border-bottom: 1px solid rgba(0, 0, 0, 0.4);
                background-color: rgba(29, 47, 44, 0.2);
                cursor: pointer !important;
            }

            &.active, &.active:hover, &.active:focus {
                border: 0px;
                background: rgba(29, 47, 44, 0.2);
                border-bottom: 3px solid rgba(0, 0, 0, 0.4);
            }
        }
    }
}

#nav-top {
    .navbar-brand {
        font-size: 24px;
        font-weight: bold;
    }

    @media (max-width: 991px) {
        #top-nav-links {
            position: absolute;
            width: 100%;
            top: 60px;
            left: 0;
            padding: 10px;
            background: rgba(0, 0, 0, 0.8);
            border: 1px solid #000;
            z-index: 999;
        }
    }
}
