#site-content {
    position: relative;
    min-width: 430px;
}

#left-content {
    position: relative;
    width: 400px;
    min-height: 500px;
}

#left-content:before, #left-content:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    top: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f8f9+0,ffffff+50,f5f7f9+100&0+0,1+50,0+100 */
    background: -moz-linear-gradient(top,  rgba(246,248,249,0) 0%, rgba(255,255,255,0.4) 50%, rgba(245,247,249,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(246,248,249,0) 0%,rgba(255,255,255,0.4) 50%,rgba(245,247,249,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(246,248,249,0) 0%,rgba(255,255,255,0.4) 50%,rgba(245,247,249,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f6f8f9', endColorstr='#00f5f7f9',GradientType=0 ); /* IE6-9 */
}

#left-content:after {
    right: 0;
}

#left-content, #right-content {
    background-color: #172934;
    box-shadow: 0 0 20px #000;
}

#left-content-body {
    padding: 15px;
}

#right-content {
    display: block;
    min-width: 400px;
    height: 350px;
    overflow: hidden;
    border: 1px solid #000;

    .tab-content {
        padding: 10px;
    }
}

.box-main {
    border: 1px solid #1E312D;
    border-radius: 0px;
    background-color: rgba(29, 47, 44, 0.5);
}

@media screen and (max-width: 991px) {
    #right-content {
        margin-top: 30px;
    }
}