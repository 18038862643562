.input-group > .form-control,
.input-group > .input-group-prepend > .input-group-text,
.btn,
.btn-group > .btn {
    border-radius: 0;
}

.input-group-text {
    background-color: rgba(29, 47, 44, 1);
    border-color: rgba(0, 0, 0, 0.3);

    > .glyphicon, > .fa, > .fas {
        color: #66857D;
    }
}

.form-control {
    background-color: rgba(29, 47, 44, 0.5) !important;
    border-color: rgba(0, 0, 0, 0.3);
    color: #628F82;

    &:focus {
        color: #628F82;
    }

    &::-webkit-input-placeholder {
        color: #436256 !important;
    }

    &::-moz-placeholder {
        color: #436256 !important;
    }

    &:-ms-input-placeholder {
        color: #436256 !important;
    }
}

.btn-primary {
    border-color: #115575 !important;
    background: #115575 !important;

    &:hover,
    &:focus {
        background: #115575 !important;
    }

    &:focus {
        box-shadow: 0 0 0 3px #5d918c !important;

        &:not(:hover) {
            background: #0f4d58 !important;
        }
    }
}

.btn-outline-secondary {
    border-color: rgba(163, 167, 163, 0.14) !important;

    &:hover,
    &:focus {
        background: rgba(163, 167, 163, 0.14) !important;
        color: #fff !important;
    }
}

.form-group {
    .input-group-text {
        background-color: #1d2f2c;
        border-color: rgba(0, 0, 0, 0.3);
    }

    .input-group-text.is-invalid,
    .form-control.is-invalid {
        border-color: #600c00;
    }

    .invalid-feedback {
        display: block;
        color: #870005;
        margin: 5px 0 0 0;
        font-size: 14px;

        > div {
            display: flex;
        }

        .badge {
            display: flex;
            align-items: center;
            border-radius: 0;
            background-color: #a81725;
        }

        .form-error-message {
            flex-grow: 1;
            padding: 2px 5px;
            border: 1px dashed #600c00;
            border-left: 0;
        }
    }
}