#news-list {
    position: relative; 
    height: 250px; 
    color: #aaa;
    overflow: hidden; 
    border: 1px solid rgba(0,0,0,0.5); 
}

.simple-news {
    &:first-child > td {
        border: 0;
    }

    .news-top { 
        height: 15px; 
        font-size: 10px; 
        border-bottom: 1px dotted rgba(0, 0, 0, 0.5); 
        text-align: right; 
        font-style: italic; 
    }

    .news-title { 
        font-weight: bold;
        text-align: right;
    }

    .news-text { 
        word-wrap: break-word; 
        text-indent: 10px; 

        a {
            font-weight: bold;
        }
    }
}