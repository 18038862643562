@import "main/base/_reset";
@import "main/base/_base";

@import "common/layout/language_bar";

@import "main/layout/_grids";
@import "main/layout/_forms";
@import "main/layout/_nav";
@import "main/layout/_footer";
@import "main/layout/_header";
@import "main/layout/_rest";

@import "main/components/_alerts";
@import "main/components/_news";
@import "main/components/_slides";