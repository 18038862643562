.alert {
    border-radius: 0;
}

#alert-container {
    position: absolute;
    width: 400px;
    top: 0;
    right: 0;
    margin: 20px;
    background-color: transparent;
    z-index: 999;
    border: 0;

    &:empty {
        display: none;
    }

    .alert {
        .close {
            color: #ccc;
            opacity: 1;
            text-shadow: none;
        }

        &.alert-danger {
            background: #600c00;
            border-color: transparent;
            box-shadow: 0 0 30px 20px #600c00;
            color: #ccc;
        }

        &.alert-success {
            background: #05231f;
            border-color: transparent;
            box-shadow: 0 0 30px 20px #05231f;
            color: #ccc;
        }

        &.alert-info {
            background: #093c59;
            border-color: transparent;
            box-shadow: 0 0 30px 20px #093c59;
            color: #ccc;

            a {
                color: #aaa;
            }
        }
    }
}