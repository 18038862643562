* { 
    outline: none!important; 
    padding: 0;
    margin: 0;
};

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

img {
    border: 0;
}

a, a:hover, a:focus { 
    text-decoration: none; 
    color: #000;
}