body {
    min-width: 430px;
    height: 100%;
    font-family: tahoma, verdana, arial, sans-serif;
    //font-size: 11px;
    line-height: 1.3;
    color: #000;
    background: #000 url('../../../images/bg.jpg') center top no-repeat;
}

.mg-top-30 {
    margin-top: 30px;
}

.separator-vertical {
    height: 2px;
    margin: 20px 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.img-thumbnail {
    background-clip: content-box;
    border-radius: 0px;
    padding: 0px;
    border: 5px solid rgba(0, 0, 0, 0.8);
}

.card-text {
    font-size: 14px;
}
